import archityperennerBoldWOFF from "./Fonts/rennerboldarchitype-webfont.woff"
import archityperennerBoldWOFF2 from "./Fonts/rennerboldarchitype-webfont.woff2"
import rennerMediumWOFF from "./Fonts/renner_500_medium-webfont.woff"
import rennerMediumWOFF2 from "./Fonts/renner_500_medium-webfont.woff2"

export default {
    archityperennerBoldWOFF,
    archityperennerBoldWOFF2,
    rennerMediumWOFF,
    rennerMediumWOFF2
}