import { createGlobalStyle, withTheme } from "styled-components"
import { media } from "utils/Media"
import fontFiles from "./Fonts";

const GlobalStyles = createGlobalStyle`   
   
    @font-face {
        font-family: 'RennerMedium';
        src: url("${fontFiles.rennerMediumWOFF2}") format('woff2'),
            url("${fontFiles.rennerMediumWOFF}") format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }
    
    @font-face {
        font-family: 'RennerArcBold';
        src: url("${fontFiles.archityperennerBoldWOFF2}") format('woff2'),
            url("${fontFiles.archityperennerBoldWOFF}") format('woff');
        font-weight: normal;
        font-style: normal;
        font-display: swap;
    }

    html, body {
        /* overflow: hidden; // causes animation bug on parallax */
    }

    body {
        font-family: 'RennerMedium', ${props => props.theme.font.family.body};
        font-size: ${props => props.theme.font.size.md};
        line-height: ${props => props.theme.font.lineHeight.base};
        color: ${props => props.theme.colors.white};
        background-color: ${props => props.theme.colors.black};
        -webkit-font-smoothing: antialiased;
        overflow-x: hidden;
        /* transition: ${props => props.theme.transitionBase}; */
/* 
        width: auto;
        height: 100vh;
        overflow: auto;
        margin: 0;  */

        &.nav-active {
            overflow: hidden;
        }
    }

    body:not(.user-is-tabbing) button:focus,
    body:not(.user-is-tabbing) input:focus,
    body:not(.user-is-tabbing) select:focus,
    body:not(.user-is-tabbing) textarea:focus,
    body:not(.user-is-tabbing) div[role="button"]:focus {
        outline: none;
    }
    
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        color: ${props => props.theme.colors.white};
        font-family: ${props => props.theme.font.family.bold};
        font-weight: ${props => props.theme.font.weight.regular};
        line-height: ${props => props.theme.font.lineHeight.headings};
        margin-bottom: 1rem;
    }

    h1, h2, h3 {
        font-family: ${props => props.theme.font.family.atp}; 
        text-transform: uppercase;
    }

    h1 {
        font-size: ${props => props.theme.font.h3.size};
        @media ${media.sm} {
            font-size: ${props => props.theme.font.h2.size};
        }
        @media ${media.md} {
            font-size: ${props => props.theme.font.h1.size};
        }
        @media ${media.xl} {
            font-size: ${props => props.theme.font.h1.xl.size};
        }
    }

    h2 {
        font-size: ${props => props.theme.font.h4.size};
        @media ${media.sm} {
            font-size: ${props => props.theme.font.h3.size};
        }
        @media ${media.md} {
            font-size: ${props => props.theme.font.h2.size};
        }
    }

    h3 {
        font-size: ${props => props.theme.font.h4.size};
        @media ${media.md} {
            font-size: ${props => props.theme.font.h3.size};
        }
    }

    h4 {
        font-size: ${props => props.theme.font.h4.size};
    }

    h5 {
        font-size: ${props => props.theme.font.h5.size};
    }

    h6 {
        font-size: ${props => props.theme.font.h6.size};
    }

    p {
        font-size: ${props => props.theme.font.p.size};
        @media ${media.md} {
            font-size: ${props => props.theme.font.p.md.size};
        }
        &:last-child {
            margin: 0;
        }
    }

    a {
        color: ${props => props.theme.colors.white};
        font-weight: ${props => props.theme.font.weight.bold};
        transition: color .4s ease;

        &:hover {
            color: ${props => props.theme.colors.white}; 
            text-decoration: underline;
        }
    }

    strong {
        font-weight: ${props => props.theme.font.weight.bold};
    }

    ul {
        padding-left: 20px;
    }

    img {
        max-width: 100%;
    }

    hr {
        border-color: ${props => props.theme.colors.quaternary};
        border-style: solid;
        margin: 1.5rem 0;
    }

    .modal-backdrop.show {
        opacity: 0.9;
        background-color: ${props => props.theme.colors.primary};
    }

    .d-xxl-block {
        @media ${media.xxl} {
            display: block !important;
        }
    }

    .d-xxl-none {
        @media ${media.xxl} {
            display: none !important;
        }
    }

    .LazyLoad {
        width: 100%;
    }


    /* light gallery */
    .lg-backdrop {
        background-color: ${props => props.theme.colors.primary};
        
        &.in {
            opacity: .9;
        }
    }

    .lg-toolbar {
        background-color: rgba(0, 0, 0, 1);
    }

    .lg-outer {
        direction: ltr; /* rtl for AR break it */
    }
`;

export default withTheme(GlobalStyles);