import React, { Component } from "react"
import PropTypes from "prop-types"
import 'scss/main.scss'
import GlobalStyles from "utils/GlobalStyles";
import theme from "utils/Theme"
import { ThemeProvider } from "styled-components"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { DrawSVGPlugin } from "gsap/DrawSVGPlugin"
import Scrollbar from "smooth-scrollbar"
// import Header from "components/Header/Header"

if (typeof window !== `undefined`) {
	gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin)
}

class Layout extends Component {
	componentDidMount() {
		this.initSmoothScrolling()
	}

	initSmoothScrolling() {
		
		let scrollPositionX = 0,
			scrollPositionY = 0,
			bodyScrollBar = Scrollbar.init(document.body, { damping: 0.1, delegateTo: document });
	
		bodyScrollBar.addListener(({ offset }) => {  
			scrollPositionX = offset.x;
			scrollPositionY = offset.y;
		});
		
		bodyScrollBar.setPosition(0, 0);

		// Removes scrollbars
		// bodyScrollBar.track.xAxis.element.remove();
		// bodyScrollBar.track.yAxis.element.remove();
		
		ScrollTrigger.scrollerProxy(".scroller", {
			scrollTop(value) {
				if (arguments.length) {
				bodyScrollBar.scrollTop = value;
				}
				return bodyScrollBar.scrollTop;
			}
		});
		
		bodyScrollBar.addListener(ScrollTrigger.update);

		// This part is only necessary if you're using ScrollTrigger's markers:
		if (document.querySelector('.gsap-marker-scroller-start')) {		
			const markers = gsap.utils.toArray('[class *= "gsap-marker"]');	
		
			bodyScrollBar.addListener(({ offset }) => {  
				gsap.set(markers, { marginTop: -offset.y })
			});
		}
		// End section necessary only if you're using ScrollTrigger's markers
	}

	render() {
		return (
			<>
				<ThemeProvider theme={theme}>
					<>
						<GlobalStyles />
						<div className="scroller" style={{ height: "100vh"}}>
							<main>{this.props.children}</main>
						</div>
					</>
				</ThemeProvider>
			</>
		)
	}
}

Layout.propTypes = {
	children: PropTypes.node.isRequired,
}

export default Layout